<template>
	<AppLayout>
		<div class="page">
			<img src="../images/banner1.png" alt="" style="width: 100%;height: auto;">
			<div class="container">
				<img src="../images/title1.png" alt="" style="width: 368px;height: 61px;margin-top: 57px;">
				<div class="text">15年行业积累，深耕硬件和主机领域。<br>
					累计服务超1000万个人及企业用户我们的专业顾问与您一起讨论性能需求，我们与客户建立关系，以便更好地理解、服务、匹配您的需求。</div>
				<div class="standard-list">
					<div v-for="item in standardList" class="standard-li">
						<img :src="item.icon" alt="">
						<div class="container-text1">{{item.text1}}</div>
						<div class="container-text2" style="white-space: pre-wrap;">{{item.text2}}</div>
					</div>
				</div>
				<div style="height: 102px;"></div>
			</div>
		</div>
	</AppLayout>
	<home-backup/>
</template>

<script setup>

import AppLayout from "@/components/AppLayout.vue";
import HomeBackup from "@/views/home/components/HomeBackup.vue";

const standardList = [{
	icon: require('../images/安全@2x.png'),
	text1: '一站式服务',
	text2: '满足您对计算机设备软硬件\n采购、定制、部署、保养、\n维修、调试的一切需求'
},{
	icon: require('../images/免费上门@2x.png'),
	text1: '严苛品控',
	text2: '每台设备出厂时\n严格经过36道NPI测试\n确保品质无忧'
},{
	icon: require('../images/快递运输@2x.png'),
	text1: '极速交货',
	text2: '依托自建智能化工厂\n线上下单\n24H顺丰发货'
},{
	icon: require('../images/V客服@2x.png'),
	text1: '全年无休',
	text2: '一对一客服，\n全年无休随时响应您的需求'
},{
	icon: require('../images/地图@2x.png'),
	text1: '全国覆盖',
	text2: '上门网点可实现\n全国区县级区域覆盖'
},{
	icon: require('../images/闪电 (5)@2x.png'),
	text1: '2H响应',
	text2: '售后团队两小时内\n响应您的故障报修\n并给出解决方案'
},{
	icon: require('../images/闹钟.png'),
	text1: '48H完工',
	text2: '售后工程师在备件满足的情况下\n48小时内完成维修'
},{
	icon: require('../images/保修@2x.png'),
	text1: '3年保修',
	text2: '整机及主要部件享受\n3年保内免费维修服务'
},{
	icon: require('../images/技术.png'),
	text1: '终身免费技术支持',
	text2: '终身免费的远程技术支持服务'
}]
</script>

<style scoped lang="less">
.page{
	background-color: #f8f8f8;
	.container{
		width: 1282px;
		margin: 0 auto;
		text-align: center;
		.text{
			margin-top: 30px;
			height: 51px;
			font-family: PingFang SC;
			font-weight: 400;
			font-size: 20px;
			color: #808490;
			line-height: 30px;
		}
		.standard-list{
			margin-top: 39px;
			display: flex;
			flex-wrap: wrap;
			align-content: space-around;
			width: 100%;
			height: 755px;
			background-color: #fff;
			.standard-li{
				width: 420px;
				img{
					width: 46px;
					height: 46px;
				}
				.container-text1{
					margin-top: 26px;
					height: 23px;
					font-family: PingFang SC;
					font-weight: 400;
					font-size: 24px;
					color: #000000;
					line-height: 22px;
				}
				.container-text2{
					margin-top: 22px;
					height: 69px;
					font-family: PingFang SC;
					font-weight: 400;
					font-size: 18px;
					color: #808490;
					line-height: 26px;
				}
			}
		}
	}
}
</style>